.legend-container {
  margin-top: 5px;
  padding-top: 0;
  text-align: center;

  @media (width >= 800px) {
    padding: 20px;
  }

  @media (width <= 800px) {
    padding: 0;
  }
}

.legend-row {
  display: inline-block;
  margin-right: $space-xs-px;
  margin-top: $space-xxs-px;

  &__text {
    cursor: pointer;
    display: inline;
    line-height: 26px;
  }

  &__color-block {
    display: inline-block;
    padding-right: $space-xxs-px;
  }

  &--disabled {
    opacity: 0.2;
  }
}
