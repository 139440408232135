// Drawer styles
body.Drawer__Body--open {
  overflow: hidden;
}

.zen-drawer {
  animation: slide-in 300ms cubic-bezier(0.2, 0.2, 0.38, 0.9);
  background: #fafafb;
  border-radius: 20px 20px 0 0;
  box-shadow: 0 4px 4px 0 rgba(0 0 0 / 25%);
  display: flex;
  flex-direction: column;
  outline: none;
  position: relative;
  width: 100vw;

  @keyframes slide-in {
    from {
      transform: translateY(100%);
    }

    to {
      transform: translateY(0);
    }
  }

  // Height is always full screen in landscape mode
  @media (min-width: $min-mobile-width-landscape) and (max-width: $max-mobile-width-landscape) {
    height: calc(100dvh - var(--navbar-height));
  }

  &--full-screen {
    height: calc(100dvh - var(--navbar-height));
  }

  &__body {
    flex: 1;
    overflow: auto;
    padding: 20px 16px 80px;

    &--full-screen {
      padding-bottom: 20px;
    }

    @media (width >= $min-mobile-width-landscape) and (width <= $max-mobile-width-landscape) {
      padding-bottom: 20px;
      padding-left: $landscape-side-padding;
      padding-right: $landscape-side-padding;
    }
  }

  &__btn {
    color: $slate;
    cursor: pointer;
    font-size: 18px;
    margin: 0;
    padding: 20px;

    &:hover {
      opacity: 0.5;
    }
  }

  &__back-btn {
    @media (width >= $min-mobile-width-landscape) and (width <= $max-mobile-width-landscape) {
      padding-left: $landscape-side-padding;
    }

    &--visible {
      visibility: visible;
    }

    &--hidden {
      visibility: hidden;
    }
  }

  &__close-x-btn {
    @media (width >= $min-mobile-width-landscape) and (width <= $max-mobile-width-landscape) {
      padding-right: $landscape-side-padding;
    }
  }

  &__overlay {
    align-items: end;
    background-color: rgba(0 0 0 / 30%);
    display: flex;
    inset: 0;
    justify-content: center;
    position: fixed;
    z-index: 9999;
  }

  &__header {
    align-items: center;
    align-self: stretch;
    display: flex;
    justify-content: space-between;
  }

  &__title {
    display: inline-block;
    flex-grow: 2;
    margin: 0;
    text-align: center;
  }
}
