.zen-progress-modal {
  &__header {
    display: grid;
    grid-template-columns: 1fr auto 1fr;
    height: 100%;
    place-items: center;

    &--disabled {
      color: $gray;
    }

    &--current {
      border-bottom: 3px solid $blue-primary;
      padding-bottom: $space-xxs-px;
    }
  }

  &__header-icon {
    margin-right: 0;
    padding: $space-xs-px;
  }

  &__title {
    margin-right: auto;
  }

  &__footer {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
}
