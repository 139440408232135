/* stylelint-disable no-descending-specificity */
// This is the primary table structure definition for the unpublished fields and
// dimension tables.
$unpublished-item-table-border: 1px solid $gray;
$unpublished-fields-table-width: 1600px;
$unpublished-dimensions-table-width: 1000px;

.cs-unpublished-items-table-container {
  &__header-bar {
    background-color: $site-background;

    // Sticky header row
    position: sticky;
    top: var(--navbar-height);
    z-index: 2;
  }

  &__subheader {
    align-items: center;
    display: flex;
    padding-bottom: $space-m-px;
    padding-top: $space-m-px;
  }

  &__table-header-wrapper {
    border: $unpublished-item-table-border;
    border-radius: 5px 5px 0 0;
    overflow-x: hidden;
  }
}

.cs-unpublished-items-table-wrapper {
  background-color: $white;
  border-bottom: $unpublished-item-table-border;
  border-left: $unpublished-item-table-border;
  border-radius: 0 0 5px 5px;
  border-right: $unpublished-item-table-border;
  overflow-x: auto;
}

.cs-unpublished-dimensions-table {
  .cs-unpublished-items-table-rows {
    // Subtract the width of the left/right borders.
    min-width: $unpublished-dimensions-table-width - 2px;

    &__header-row {
      grid-template-columns: 300px 3fr 2fr 5fr;

      // Subtract the width of the left/right borders.
      min-width: $unpublished-dimensions-table-width - 2px;
    }

    &__row {
      grid-template-columns: 300px 3fr 2fr 5fr;
    }
  }
}

.cs-unpublished-fields-table {
  .cs-unpublished-items-table-rows {
    // Subtract the width of the left/right borders.
    min-width: $unpublished-fields-table-width - 2px;

    &__header-row {
      grid-template-columns: 300px 4fr 4fr 5fr 5fr 5fr 5fr;

      // Subtract the width of the left/right borders.
      min-width: $unpublished-fields-table-width - 2px;
    }

    &__row {
      grid-template-columns: 300px 4fr 4fr 5fr 5fr 5fr 5fr;
    }
  }
}

.cs-unpublished-items-table-rows {
  &__header-row {
    background-color: $white;
    display: grid;

    &:hover {
      .cs-unpublished-items-table-rows__header-checkbox {
        visibility: visible;
      }
    }

    // For all row column cells, align items and add a border. Last header cell
    // needs to round the top left border.
    & > * {
      align-items: flex-start;
      display: flex;
      padding: $row-padding;

      &:first-of-type {
        background-color: $white;
        border-radius: 5px 0 0;
        border-right: $unpublished-item-table-border;
        box-shadow: 1px 0 1px rgb(9 30 66 / 9%), 2px 0 4px rgb(9 30 66 / 18%);
        left: 0;
        position: sticky;
        z-index: 1;
      }
    }
  }

  & > * {
    &:first-of-type {
      border-top: none;
    }
  }

  &__row {
    border-top: $unpublished-item-table-border;
    display: grid;

    &:hover,
    &--selected {
      background-color: $white-hover;

      .cs-item-row__checkbox {
        visibility: visible;
      }
    }

    // For all row column cells, align items and add a border.
    & > * {
      align-items: flex-start;
      display: flex;
      padding: $row-padding;

      &:first-of-type {
        background-color: $white;
        border-right: $unpublished-item-table-border;
        box-shadow: 1px 0 1px rgba(9 30 66 / 9%), 2px 0 4px rgba(9 30 66 / 18%);
        left: 0;
        position: sticky;
        z-index: 1;
      }
    }
  }

  &__header-checkbox {
    padding-right: $space-s-px;

    &--unchecked {
      visibility: hidden;
    }
  }

  &__header-column-cell {
    border-right: $unpublished-item-table-border;
    box-shadow: 2px 0 4px rgb(0 0 0 / 15%);
  }
}

.cs-item-row {
  &__checkbox {
    padding-right: $space-s-px;
    visibility: hidden;
  }

  &__id-block {
    overflow: hidden;
  }

  &__id-text {
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__id-popover {
    @extend .zen-tooltip__popover;

    max-width: 300px;
    overflow-wrap: break-word;
  }

  &__datasource {
    padding-top: $space-l-px;
  }

  &__datasource--error {
    color: $error-color;
  }
}

.cs-editable-calculation {
  flex: 1 1 auto;
}

.cs-category-input {
  &__main-button {
    @extend .zen-dropdown-button__main-btn;
    @extend .zen-dropdown-button__main-btn--default;
  }

  &__caret {
    align-items: center;
    display: flex;
    padding-left: $space-xs-px;
  }
}

.cs-calculation-input {
  .indicator-section-row {
    align-items: center;
    display: grid;
    grid-template-columns: 1fr 1.5fr;
  }

  .indicator-section-row__title {
    padding-right: $space-xs-px;
  }
}
