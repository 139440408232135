.dashboard-explorer {
  background: $white;
  min-height: var(--main-height);
  padding: $space-m-px;

  @media (min-width: $min-mobile-width-landscape) {
    padding-left: $landscape-side-padding;
    padding-right: $landscape-side-padding;
  }
}

.dashboard-explorer-table {
  &__thumbnail-column {
    // 48px width icon + 12px padding
    width: 60px;
  }

  &__favorite-column {
    width: $space-m-px;
  }

  &__menu-column {
    width: $space-l-px;
  }

  &__row {
    border-color: $blue-lightest;
  }

  &__no-data {
    padding-top: 84px;
    text-align: center;
  }
}

.dashboard-explorer-table-cell {
  padding: $space-s-px 0;
  vertical-align: top;

  &__thumbnail {
    border: 1px solid $gray-light-active;
    border-radius: 3px;
    height: $space-xxl-px;
    width: $space-xxl-px;
  }

  &__text-cell {
    padding-right: $space-xs-px;
  }

  &__title-text {
    color: black;
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &__favorite-cell {
    // Account for the text line height to keep everything horizontally aligned
    position: relative;
    top: 3px;
  }

  &__menu-cell {
    // Account for the text line height and icon size to keep everything horizontally aligned
    position: relative;
    top: -4px;
  }

  &__menu-button {
    fill-opacity: 0.54;
    margin: 0;
  }
}

.dashboard-explorer-sort {
  display: flex;
  justify-content: flex-start;
}

.dashboard-explorer-sort-label {
  display: inline;

  &__icon {
    margin-left: $space-xxxs-px;
    padding-top: $space-xxs-px;
    vertical-align: bottom;
  }
}

.dashboard-explorer-search-input {
  input {
    border: 1px solid $gray-light-active;
    border-radius: 6px;
    height: 40px;
  }

  &__clear-button {
    color: $black;
    font-size: 14px;
    margin-left: $space-xs-px;
    padding: 0 $space-s-px;

    &--hidden {
      display: none;
    }
  }

  &__clear-button-contents {
    font-weight: 400;
    text-transform: none;
  }
}

.dashboard-explorer-search {
  align-items: baseline;
  display: flex;
}

.dashboard-explorer-filters {
  display: inline-flex;
  justify-content: flex-start;
  overflow-x: auto;
  scrollbar-width: none;
  white-space: nowrap;
  width: 100%;

  &__filter-item {
    background: $white-active;
    border: 1px solid $white-active;
    border-radius: 100px;
    color: $slate;
    display: flex;
    font-size: 14px;
    -webkit-overflow-scrolling: touch;
    padding: $space-xxs-px $space-m-px;
    text-wrap: nowrap;
    touch-action: pan-x;
    width: fit-content;

    &:last-child {
      margin-right: 0;
    }

    &--active {
      background: #c4e7ff;
      border-color: $blue-dark;
    }
  }
}

.dashboard-explorer-menu {
  color: black;

  &__favorite-row {
    background: $white;
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 32px !important;
  }

  &__dashboard-title {
    -webkit-box-orient: vertical;
    display: -webkit-box;
    font-size: 16px;
    font-weight: 500;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    overflow: hidden;
    word-break: break-word;
  }

  &__wrapped-button {
    align-items: center;
    display: flex;
    flex-direction: column;
  }

  &__button-label {
    margin-top: $space-xxs-px;
  }
}

.dashboard-explorer-scroll {
  background: $white;
  left: 0;
  padding: $space-m-px;
  position: fixed;
  right: 0;
  top: var(--navbar-height);
  z-index: 1;

  @media (min-width: $min-mobile-width-landscape) {
    padding-left: 40px;
    padding-right: 40px;
  }
}
