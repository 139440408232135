.dropdown-control {
  .zen-dropdown {
    position: relative;
  }
}

.color-control {
  &__color-block {
    align-items: center;
    display: flex;
    width: $spacing-xl;

    // NOTE(stephen): Most of the styles are applied to the color-block so that
    // we don't have to provide separate click handlers or behaviors on top of
    // the core ui component.
    .zen-color-block {
      align-items: center;
      background-color: $white;
      border: 1px solid $gray-light-active;
      border-radius: 5px;
      color: $slate;
      cursor: pointer;
      display: flex;
      height: $spacing-l;
      padding-left: $spacing-s;
      width: $spacing-xl;

      &__icon {
        border: 1px solid $gray-light-active;
        border-radius: 50%;
      }

      // NOTE(stephen): Matching Dropdown color scheme so this control looks
      // nice next to dropdowns.
      &:hover {
        background-color: $white-hover;
        border: 1px solid $gray;
      }

      &:active {
        background-color: $white-active;
        border: 1px solid $gray;
      }
    }
  }

  &__caret {
    left: -18px;
    margin: 0;
    pointer-events: none;
    position: relative;
  }
}

.color-value-rules-block {
  align-items: center;
  border: 1px solid $gray;
  border-radius: 5px;
  display: flex;
  height: 40px;

  &__border {
    background-color: $white;
    border: 1px solid $gray;
    border-radius: 5px;
    height: 24px;
    padding: 1px;
    width: 24px;
  }

  &__divider {
    border-left: 1px solid $gray;
    height: 40px;
  }
}

.transformed-text-block {
  height: 300px;
  padding: 0;

  &__text-btn {
    color: $blue-primary-active;

    :hover {
      color: $blue-primary-hover;
    }
  }

  &__textarea {
    resize: none;
  }

  &__apply-button {
    border-radius: 0 0 5px 5px;
    bottom: 0;
    color: $white;
    position: absolute;
    text-transform: uppercase;
  }

  &__tag {
    background-image: linear-gradient(to right, $white, $white);
    border: 1px solid $gray;
    color: $slate;
    display: flex;
    height: 30px;
    justify-content: center;
    margin-right: 0;

    &--content {
      max-width: 200px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}
