.embedded-query-app-container {
  background-color: $white;

  // The navbar isn't rendered
  --navbar-height: 0;
}

.embedded-query-app {
  position: relative;

  &__export-button {
    opacity: 0.7;
    position: absolute;
    right: 0;
    top: $space-xs-em;

    &:hover {
      opacity: 1;
    }
  }
}
