* {
  -webkit-font-smoothing: subpixel-antialiased;
  -moz-osx-font-smoothing: grayscale;
}

// This is carried over from smartadmin
// Makes our site hard to use for keyboard/screen reader users
*:focus {
  outline: none;
}

[role="button"],
[role="tab"] {
  cursor: pointer;
}

label {
  display: inline-block;

  // Force IE8 to wrap long content (see
  // https://github.com/twbs/bootstrap/issues/13141)
  max-width: 100%;
}

// Styling for the standard app container including the navbar
.zen-style {
  --navbar-height: #{$navbar-height};

  overflow-x: hidden;
}

// Apply font styles that we know we'll need so that the browser downloads
// them on page load instead of deferring it and causing a stutter in the
// critical rendering path.
body::after {
  content: " ";
  font-weight: 300;
}

html,
body {
  // Override the bootstrap default here to allow for better handling of heights
  // that exceed the viewport and pages with minimum heights.
  height: inherit;

  // Override the bootstrap default here since mobile has a different viewport meaning
  min-height: 100dvh;
}

body {
  background: $site-background;
  color: $slate;
  font-family: $base-font-family;
  font-size: 14px;
  font-weight: 400;
  position: relative;
}

.min-full-page-height {
  min-height: var(--main-height);
}

// Include a fallback for older browsers that don't support the `dvh` unit yet
@supports not (height: 100dvh) {
  #main {
    --main-height: calc(100vh - var(--navbar-height));
  }
}

@supports (height: 100dvh) {
  #main {
    --main-height: calc(100dvh - var(--navbar-height));
  }
}

#main {
  min-height: var(--main-height);
}

.screenshot-page {
  --navbar-height: 0;

  .hide-in-screenshot {
    display: none;
  }

  .hide-on-export {
    visibility: hidden;
  }
}

.embedded-page {
  --navbar-height: 0;

  .hide-in-embedded-app {
    display: none;
  }
}

// HACK(nina): We need to make the chatbox invisible, but still have it exist
#crisp-chatbox a[data-visible="false"],
#crisp-chatbox a[data-maximized="false"],
#crisp-chatbox a[data-visible="false"] > span:first-child > span:first-child {
  display: none !important;
}

// HACK(nina): We need to make the chat visible when opened
#crisp-chatbox a[data-visible="true"],
#crisp-chatbox a[data-visible="true"] > span:first-child > span:first-child {
  display: inherit !important;
}
